/* eslint-disable */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { appId, apiUrl, apiUrlV2, deliveryId, timThumpUrl } from "../Helpers/Config";
import cookie from 'react-cookies';
import Slider from "react-slick";
var dateFormat = require('dateformat');

import validator from 'validator'
import update from 'immutability-helper'
import { validated } from 'react-custom-validation'

import Select from 'react-select';
import Moment from 'moment';

import { stripslashes, addressFormat, showAlert, callImage } from "../Helpers/SettingHelper";

import { GET_CUSTOMER_DETAIL, GET_UPDATECUSTOMERPROFILE, GET_ACTIVITYCOUNT } from '../../actions';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import PropTypes from 'prop-types';

import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Form from "./Form";

var qs = require('qs');

class Myaccount extends Component {
	constructor(props) {

    super(props);
    this.state = {
      menu: false,
        fields: {
            firstname: '',
            lastname: '',
            nickname: '',
            email: '',
            mobile: '',
            postal: '',
            unitnumber2: '',
            unitnumber1: '',
            company: '',
            birthdate: "",
            fbid: '',
            image_source: '',
            photo: '',
            gender: ''
        },
        status: 'OK',
        date1: '',
        defaultDispaly: "",
        order_all:0,
        overall_orders:0,
  
        activityPoints: {
            reward_ponits: '',
            reward_ponits_monthly: '',
            order_all: '',
            promotion: ''
        }, ordercount: 0
    };

    if (cookie.load('UserId') == '' || cookie.load('UserId') == undefined) {
        props.history.push("/");
    }

	}

    componentDidMount() {
      var params = "&status=A&refrence=" + cookie.load('UserId');
      $('#dvLoading').fadeOut(2000);
      this.props.getCustomerDetail(params);
      this.getActivityCountsNew();

    setTimeout(function(){ if ($(".mCustomScrollbar").length > 0) {
      $(".mCustomScrollbar").mCustomScrollbar();
    } }, 400);
   }
   
   componentDidUpdate() {
	   
	//	var params = "&status=A&refrence=" + cookie.load('UserId');
   
    //  this.props.getCustomerDetail(params);
    //  this.getActivityCountsNew();
	   
	   }
 
 getActivityCountsNew() {
   const inputKeys = ["order_all", "overall_orders","reward","reward_expiry_info" ];
   this.props.getActivityCount(JSON.stringify(inputKeys));
 }

   fieldChange = (field, value) => {
       
       this.setState(update(this.state, { fields: { [field]: { $set: value } } }))
   }


   /* To Submit the personal informartion form */
   handleFormSubmit = () => {

       const formPayload = this.state.fields;

       var postObject = {
           "app_id": appId,
           "type": "web",
           "customer_first_name": formPayload.firstname,
           "customer_last_name": formPayload.lastname,
           "customer_nick_name": formPayload.nickname,
           "customer_phone": formPayload.mobile,
           "customer_email": formPayload.email,
           "customer_birthdate": cookie.load('birthdate').replace("/", "-").replace("/", "-"),
           'customer_postal_code': formPayload.postal,
           'customer_address_name2': formPayload.address1,
           'customer_company_name': formPayload.company,
           'customer_gender': formPayload.gender.value,
           'customer_address_line1': formPayload.unitnumber1,
           'customer_address_line2': formPayload.unitnumber2,
           'customer_id': cookie.load('UserId')
       };
       $(".myaccount_update").append('<b class="gloading_img"></b>');
        this.props.getUpdateCustomerProfile(qs.stringify(postObject));

   }


   componentWillReceiveProps(nextProps) {
   
 
      if(nextProps.updatecustomerprofile !== this.props.updatecustomerprofile){

      const {updatecustomerprofile} = nextProps;
      if (updatecustomerprofile[0].status === "ok") {
        $(".myaccount_update gloading_img").remove();
        cookie.save("UserFname", updatecustomerprofile[0].result_set.customer_first_name);
        cookie.save("UserLname", updatecustomerprofile[0].result_set.customer_last_name);
        cookie.save("UserMobile", updatecustomerprofile[0].result_set.customer_phone);
        cookie.save("UserEmail", updatecustomerprofile[0].result_set.customer_email);
        cookie.save("UserDefaultAddress", updatecustomerprofile[0].result_set.customer_address_name);
        cookie.save("UserDefaultUnitOne", updatecustomerprofile[0].result_set.customer_address_line1);
        cookie.save("UserDefaultUnitTwo", updatecustomerprofile[0].result_set.customer_address_line2);
        cookie.save("UserDefaultPostalCode", updatecustomerprofile[0].result_set.customer_postal_code);

        /* Redirect to checkout page .. */
        if (cookie.load('isChecoutRedirect') === "Yes" && cookie.load('cartValue') === "Yes") {
          cookie.save('isChecoutRedirect', 'No');
          /*hashHistory.push("/checkout");*/
        }
        window.location.reload();

      } else {
        //this.setStateLoading('ok');

        if (updatecustomerprofile[0].form_error) {
          document.getElementById("form-msg").innerHTML = '<div className="box_error"><ul><li>' + updatecustomerprofile[0].form_error + '</li></ul></div>';
        } else {
          document.getElementById("form-msg").innerHTML = '<div className="box_error"><ul><li>' + updatecustomerprofile[0].message + '</li></ul></div>';
        }
      }

   }

    if(nextProps.customerdetail !== this.props.customerdetail){

      if (nextProps.customerdetail[0].status === "ok") {
            $('#dvLoading').fadeOut(2000);
            if (nextProps.customerdetail[0].result_set.customer_birthdate == null || nextProps.customerdetail[0].result_set.customer_birthdate == '0000-00-00') {
                var birthdate = '';
            } else {
                var birthdate = nextProps.customerdetail[0].result_set.customer_birthdate;
                var birthyear = birthdate.substring(0, 4);
                var birthmonth = birthdate.substring(5, 7);
                var birthdatev = birthdate.substring(8, 10);
                var birthdateTemp = birthdatev + "/" + birthmonth + "/" + birthyear;
                cookie.save('birthdate', birthdateTemp);
            }
          
            this.setState({
                fields: {
                    firstname: (nextProps.customerdetail[0].result_set.customer_first_name !== null ? nextProps.customerdetail[0].result_set.customer_first_name : ''),
                    birthdate: birthdate,
                    lastname: (nextProps.customerdetail[0].result_set.customer_last_name !== null ? nextProps.customerdetail[0].result_set.customer_last_name : ''),
                    nickname: (nextProps.customerdetail[0].result_set.customer_nick_name !== null ? nextProps.customerdetail[0].result_set.customer_nick_name : ''),
                    email: (nextProps.customerdetail[0].result_set.customer_email !== null ? nextProps.customerdetail[0].result_set.customer_email : ''),
                    mobile: (nextProps.customerdetail[0].result_set.customer_phone !== null ? nextProps.customerdetail[0].result_set.customer_phone : ''),
                    photo: (nextProps.customerdetail[0].result_set.customer_photo !== null &&  nextProps.customerdetail[0].result_set.customer_photo !== '' ? nextProps.customerdetail[0].common.image_source + nextProps.customerdetail[0].result_set.customer_photo : ''),
                    postal: (nextProps.customerdetail[0].result_set.customer_postal_code !== null ? nextProps.customerdetail[0].result_set.customer_postal_code : ''),
                    unitnumber2: (nextProps.customerdetail[0].result_set.customer_address_line2 !== null ? nextProps.customerdetail[0].result_set.customer_address_line2 : ''),
                    unitnumber1: (nextProps.customerdetail[0].result_set.customer_address_line1 !== null ? nextProps.customerdetail[0].result_set.customer_address_line1 : ''),
                    address: (nextProps.customerdetail[0].result_set.customer_address_name !== null ? nextProps.customerdetail[0].result_set.customer_address_name : ''),
                    address1: (nextProps.customerdetail[0].result_set.customer_address_name2 !== null ? nextProps.customerdetail[0].result_set.customer_address_name2 : ''),
                    company: (nextProps.customerdetail[0].result_set.customer_company_name !== null ? nextProps.customerdetail[0].result_set.customer_company_name : ''),
                    fbid: nextProps.customerdetail[0].result_set.customer_fb_id,
                    image_source: nextProps.customerdetail[0].common.image_source,
                    juicedid: (nextProps.customerdetail[0].result_set.customer_unique_id !== null ? nextProps.customerdetail[0].result_set.customer_unique_id : ''),
                    joinedOn: (nextProps.customerdetail[0].result_set.customer_created_on !== null ? nextProps.customerdetail[0].result_set.customer_created_on : ''),
                    gender: (nextProps.customerdetail[0].result_set.customer_gender !== null &&nextProps.customerdetail[0].result_set.customer_gender !== '' ? nextProps.customerdetail[0].result_set.customer_gender : 'M'),

                }, status: nextProps.customerdetail[0].status

            });


            var vDefaultAddr = '';
            var list = document.getElementsByClassName('form-group-input');
            var n;
            for (n = 0; n < list.length; ++n) {
                if (list[n].getElementsByTagName('input')[0].value !== '') {
                    list[n].classList.remove("is-empty");
                } else {
                    list[n].classList.add("is-empty");
                }
            }
        }

    }

    /*activity count -start */
    if(Object.keys(nextProps.activitycount).length > 0){
     if(nextProps.activitycount !== this.props.activitycount){
       if(nextProps.activitycount[0].status && nextProps.activitycount[0].result_set){
         this.setState({ order_all: nextProps.activitycount[0].result_set.order_all, overall_orders: nextProps.activitycount[0].result_set.overall_orders,
          activityPoints: {
            reward_ponits: nextProps.activitycount[0].result_set.reward_ponits,
            reward_ponits_monthly: nextProps.activitycount[0].result_set.reward_expiry_info,
        }
        });
       }
     }
    } else{
     this.setState({ order_all: 0, overall_orders: 0 });
    }

   }

  handleMenu(){
    this.setState(prevState => ({menu: !prevState.menu})); 

  }

	render() {

   
    
		var settingsMyaccNav = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 2,
			slidesToScroll: 1,
			initialSlide: 0,
			responsive: [
			  {
				breakpoint: 1024,
				settings: {
				  slidesToShow: 2,
				  slidesToScroll: 1,
				  infinite: true,
				  dots: false
				}
			  },
			  {
				breakpoint: 700,
				settings: {
				  slidesToShow: 2,
				  slidesToScroll: 1,
				  initialSlide: 0
				}
			  },
			  {
				breakpoint: 520,
				settings: {
				  slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide:0
				}
			  }
			]
		  };

		return (
				<div className="home-wrapper inner-page-wrapper">

					{/*-------Header---------*/}	
					<Header />

					<div className="myaccount-wrapper">		
					<div className="myaccount-container">
						<div className="myaccount-sec">
							<div className="nav-sec">
								<ul className="navlist">
									<li className="active"><Link to="/myaccount" title="My Account">Account Information</Link></li>
									<li><Link to="/myorders" title="My Orders">Orders {(parseFloat(this.state.overall_orders)>0) && <span className="count" id="masterCount">{this.state.overall_orders}</span>}</Link></li>
									<li><Link to="/rewards" title="My Rewards">Rewards</Link></li>
									<li><Link to="/mypromotions" title="My Promotions">Promotions </Link></li>
								</ul>
                <div className="mobi-product-menu-list myacc-menu-list">
                  <Slider {...settingsMyaccNav}>                  
										<div className="menu-slide active">
										<Link to="/myaccount" title="My Account">Account Information</Link>
										</div>												
										<div className="menu-slide">
										<Link to="/myorders" title="My Orders">Orders {(parseFloat(this.state.overall_orders)>0) && <span className="count" id="masterCount">{this.state.overall_orders}</span>}			</Link>							
										</div>												
										<div className="menu-slide">	
										<Link to="/rewards" title="My Rewards">Rewards</Link>								
										</div>												
										<div className="menu-slide">
										<Link to="/mypromotions" title="My Promotions">Promotions </Link>									
										</div>		
                  </Slider>
              </div>
							</div>
						
                  <Form {...this.props}
											  fields={this.state.fields}
											  activityPoints={this.state.activityPoints}
											  onChange={this.fieldChange}
											  onValid={this.handleFormSubmit}
											  onInvalid={(error) => console.log(error,'Form invalid!')}
											  />  

						</div>
					</div>
					</div>
					

					
					{/*------Footer--------*/}
					<div className="footer footer-bg">
						<Footer />
					</div>

			    </div>
			   )
	}	
}


const mapStateToProps = (state) => {
  return {
    customerdetail: state.customerdetail,
    updatecustomerprofile: state.updatecustomerprofile,
    activitycount: state.activitycount,
  }
}

const mapDispatchToProps = (dispatch) => {
return {
  getCustomerDetail: (params) => {
    dispatch({ type: GET_CUSTOMER_DETAIL, params});
  },
  getUpdateCustomerProfile: (formPayload) => {
    dispatch({ type: GET_UPDATECUSTOMERPROFILE, formPayload});
  },
  getActivityCount: (getObject) => {
  dispatch({ type: GET_ACTIVITYCOUNT, getObject});
},
}
}

Myaccount.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }) 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Myaccount));
