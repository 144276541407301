/* eslint-disable */
import React, { Component } from 'react';
import { Link,withRouter } from 'react-router-dom';
import cookie from 'react-cookies';
import { connect } from 'react-redux';

import { stripslashes, hideLoader, showAlert} from "../Helpers/SettingHelper";

import { appId, deliveryId } from "../Helpers/Config";

import update from 'immutability-helper'

import { validated } from 'react-custom-validation'
import validator from 'validator'

import CartSideBar from "../Layout/CartSideBar";

import logo from "../../common/images/logo.png";
import logo1 from "../../common/images/logo-new.png";

import homeBg from "../../common/images/home-bg.jpg";
import img1 from "../../common/images/img1.jpg";
import FB_blue from "../../common/images/fb-blue.png";
import Instgram_blue from "../../common/images/instgram-blue.png";
import fLogo from "../../common/images/f-logo.png";
import FB_white from "../../common/images/fb-white.png";
import Instgram_white from "../../common/images/instgram-white.png";
import mail_blue from "../../common/images/mail-blue.png";
import scroll_bottom from "../../common/images/scroll-bottom.png";
import scroll_bottom_blue from "../../common/images/scroll-bottom-blue.png";
import scroll_top from "../../common/images/scroll-top.png";
import profile_mobile from "../../common/images/profile_mobile.png";
import ico_login from "../../common/images/user.png";


import {GET_GLOBAL_SETTINGS,GET_ZONE_DETAIL, GET_MENUDATA, GET_CONTACTDATA, GET_CONTACTCONTENT,GET_STATIC_BLOCK,GET_SOCIALSETTINGS} from '../../actions';

var Parser = require('html-react-parser');

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menu: false,
			menuData:[],
			footerSocialcontent:'',
			fields: {
				name: '',
				mobile: '',
				subject: '',
				email: '',
				type: '',
				message: '',
			}, 
			contactinfo:'',
			footer_contact_info:'',
			home_banner_content_one:'',
			home_banner_content_two:''
		}

	  }
 
	componentDidMount() {
			
			this.props.getGlobalSettings();
			
			
			var availbty = cookie.load('defaultAvilablityId');
			var outltIdTxt = (typeof cookie.load('orderOutletId') === 'undefined') ? '' : cookie.load('orderOutletId');
			var zoneIdTxt = (typeof cookie.load('orderZoneId') === 'undefined') ? '' : cookie.load('orderZoneId');

			if(availbty === deliveryId && outltIdTxt !== '' && zoneIdTxt !== '') {
				this.state['delivery_outlet_id'] = outltIdTxt;
				this.props.getZoneDetail(outltIdTxt, zoneIdTxt);
			}
			
			
			this.props.getMenuData('main');
			this.props.getStaticBlock();
			this.props.getSocialsetting();
            /*
			var bgVideo = document.getElementById("bg-video");
			bgVideo.play();			
			this.videoControls();
			*/
			/*
			var video = document.getElementById("bg-video");
			var playButton = document.getElementById("play_button");
			playButton.addEventListener("click", function() {
			  if(video.paused == true) {
				video.play();
				playButton.innerHTML = "Pause";
			  }else {
				video.pause();
				playButton.innerHTML = "Play";
			  }
			});
            */ 
	}
	
	/*
	videoControls() {
	  document.getElementById("bg-video").controls = true;
	}
	*/

	componentDidUpdate (prevProps) {

		if (this.props.socialsettingData !== prevProps.socialsettingData) {

			   this.setState( { footerSocialcontent: this.props.socialsettingData[0].result_set });
		   }

   }

	componentWillReceiveProps(nextProps) {

		if(nextProps.menudata !== this.props.menudata){
			this.setState({menudata: nextProps.menudata[0].result_set});
		}
		
		if(nextProps.contactdata !== this.props.contactdata)
		{	
			if(nextProps.contactdata[0].status === 'ok')
			{
				this.handleShowAlertFun('success',nextProps.contactdata[0].message);

				setTimeout(function () {

					window.location.reload();

				}, 3000);
			}
			else {
				this.handleShowAlertFun('success',nextProps.contactdata[0].message);

				$.magnificPopup.open({
					items: {
						src: '.alert_popup'
					},
					type: 'inline'
				});

			}
		}

		if(nextProps.staticblock !== this.props.staticblock) {
			let contactinfo ,footer_contact_info,home_banner_content_one,home_banner_content_two = '';

			if(Object.keys(nextProps.staticblock).length > 0) {
				nextProps.staticblock.map((data, index) => {
				if(data.staticblocks_slug === 'contact-us-contact-info') {
					contactinfo = data.staticblocks_description;
					contactinfo = (contactinfo !== '') ? Parser(contactinfo) : contactinfo;
				}
				
				if(data.staticblocks_slug === 'home-footer-contact-info') {
					footer_contact_info = data.staticblocks_description;
					footer_contact_info = (footer_contact_info !== '') ? Parser(footer_contact_info) : footer_contact_info;
				}
				
				if(data.staticblocks_slug === 'home-banner-content-1') {
					home_banner_content_one = data.staticblocks_description;
					home_banner_content_one = (home_banner_content_one !== '') ? Parser(home_banner_content_one) : home_banner_content_one;
				}
				if(data.staticblocks_slug === 'home-banner-content-2') {
					home_banner_content_two = data.staticblocks_description;
					home_banner_content_two = (home_banner_content_two !== '') ? Parser(home_banner_content_two) : home_banner_content_two;
				}
				
				
			  });
			}

			this.setState({contactinfo: contactinfo,footer_contact_info:footer_contact_info,home_banner_content_one:home_banner_content_one,home_banner_content_two:home_banner_content_two});
		}

	}

	handleMenu(){
			this.setState(prevState => ({menu: !prevState.menu})); 

	}

	handleLogin(){
		cookie.save("loginpopupTrigger", 'Fromhome');
		this.props.history.push('/menu');

	}
	
	createLink(menu){
		var url = window.location.pathname;
		if(menu.nav_type==="0")
		{
		  return <Link to={"/page/"+menu.nav_title_slug} title="" className={(url === "/page/"+menu.nav_title_slug)? 'active' : ''}>
			
						  <span>{menu.nav_title}</span>
						</Link>;
	
		  }
		  else if(menu.nav_type==="3")
		  {  
			let target= ""; 
			if(menu.nav_link_type === "self"){
			  target= ""; 
			}else{
			  target = "_blank";
			}
		   return  <a target={target} href={menu.nav_pages} title={menu.nav_title}><span>{menu.nav_title}</span></a>;
		   }
		}
	
	listMainNavigation(){
			 return this.state.menudata.map(function (menu, i) {
				   return  (<li key={i+100}>{this.createLink(menu)}</li>);
		   },this)
	}
	
	/*For contact forms*/
	fieldChange = (field, value) => {
		this.setState(update(this.state, { fields: { [field]: { $set: value } } }))
	}
	
	handleFormSubmit = () => {
		const formPayload = this.state.fields;

		var qs = require('qs');
		var postObject = {
			"app_id": appId,
			"customer_first_name": formPayload.name,
			"customer_emailaddress": formPayload.email,
			"customer_phonenumber": formPayload.mobile,
			"subject": formPayload.type,
			"customer_message": formPayload.message
		};


        this.props.getContactData(qs.stringify(postObject));

	}
	
	handleShowAlertFun(header,msg) {
		var magnfPopup = $.magnificPopup.instance;
		showAlert(header, msg, magnfPopup);
		$.magnificPopup.open({
		  items: {
			src: '.alert_popup'
		  },
		  type: 'inline'
		});
    }

	
	contMapHtml = () =>{
		
		return (
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7866803955712!2d103.85657775092463!3d1.3029702620811001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19f86aeaf327%3A0x67a2bbebf321db87!2sChix%20Hot%20Chicken%20%7C%20Nashville%20Hot%20Fried%20Chicken%20Singapore!5e0!3m2!1sen!2sin!4v1588072588215!5m2!1sen!2sin" width="100%" height="520" frameBorder="0" allowFullScreen></iframe>
				);
		
	}
	

	  render() {
		  
		 let todayTimeSp = new Date();
		let yearSp = todayTimeSp.getFullYear();

		let socialsettingData = [];

		let facebook,instagram,email_info,email_banner_info ='';
		
		if(this.state.footerSocialcontent){

			socialsettingData = this.state.footerSocialcontent;
	
			if(socialsettingData.socailnetwork_facebook_url){
				facebook = <a href={socialsettingData.socailnetwork_facebook_url}  target="_blank" className="facebook icon"></a>
			}

			if(socialsettingData.socailnetwork_instagram_url){
				instagram = <a href={socialsettingData.socailnetwork_instagram_url} target="_blank" className="instagram icon"></a>
			}
		
			if(socialsettingData.socailnetwork_gplus_url){
				email_info = <a href={"mailto:"+socialsettingData.socailnetwork_gplus_url} target="_top" rel="noopener noreferrer" className="mail icon"></a>
				email_banner_info = <a href={"mailto:"+socialsettingData.socailnetwork_gplus_url} target="_top" rel="noopener noreferrer" className="email_banner">hello@chixhotchicken.com</a>
				
			}
		}

		return (
                <div className="home-wrapper">
				
				<div className="header-top">
				    <div className="container"> 
						<div className="header-inner">
							<div className="head-logo">
								<a title="logo" href="/"><img src={logo1} alt="Logo" /></a>
							</div>
							<div className="hmenu_sec">
								<ul className="hmenu_list desktop_hmenu_list">
								
								<li className="active"><Link to={"/"} title="Home"><span>Home</span></Link></li>
								<li><Link to={"/menu"} title="Menu"><span>Menu</span></Link></li>
								{this.state.menudata && this.listMainNavigation()}	
								<li><Link to={"/contact-us"} title="Contact Us"><span>Contact Us</span></Link></li>	
								
								<li className="hsign_sec">
									
								{!cookie.load("UserId") &&
								<a href="javascript:void(0)" onClick={this.handleLogin.bind(this)} title="Login">
								<span>Login</span>				
								<img src={ico_login} alt="login" />
								</a>
								}								
								{cookie.load("UserId") && 
								<Link to={"/myaccount"} title="My Account"> 
								<span>My Account</span>			
								<img src={profile_mobile} alt="profile" />	
								</Link>
								}
								</li>								
								<li className="htico_cart">
									{/*-<a href="javascript:void(0)" className="hcartdd_trigger" title="">
									<i></i><span className="hcart_round">0</span></a>*/}
									
									{/*------Cart List-------------*/}	
									<CartSideBar {...this.props} headerState={this.state} />
									
								</li>
								
								</ul>
							</div>


							<a href="javascript:void(0)" onClick={this.handleMenu.bind(this)} className={(this.state.menu===true)?'trigger-menu menu-icon active':'trigger-menu menu-icon'}>
								<span className="icon_menu1"></span>
								<span className="icon_menu2"></span>
								<span className="icon_menu3"></span>
							</a>

						</div>
					</div>					
					
					<div className={(this.state.menu===true)?'mobi-menu active':'mobi-menu'}>
						
							<a href="javascript:void(0)" onClick={this.handleMenu.bind(this)} className={(this.state.menu===true)?'trigger-menu menu-icon active':'trigger-menu menu-icon'}>
								<span className="icon_menu1"></span>
								<span className="icon_menu2"></span>
								<span className="icon_menu3"></span>
							</a>
						<ul className="mobimenu_list">
							<li><Link to={"/"} title="Home"><span>Home</span></Link></li>
								<li><Link to={"/menu"} title="Menu"><span>Menu</span></Link></li>
								{this.state.menudata && this.listMainNavigation()}	
								<li><Link to={"/contact-us"} title="Contact Us"><span>Contact Us</span></Link></li>	
						</ul>
					</div>				
					
				</div>
				
				<div className="home-section one" id="home-banner">					
					<div className="home-bg">					
					
						<div className="content-wrapper">
						  {this.state.home_banner_content_one}						  
							<Link to={"/menu"}  className="btn" title="Order Here">Order Here</Link>
						</div>							
						<div className="social-media">								
							{instagram}
							{facebook}
							
							{email_info}							
						</div>						
						{email_banner_info}
						<a href="#two" className="scroll_bottom"><img src={scroll_bottom} alt="" /></a>	

									
					
					</div>				
				</div>		
				
				{/* <div className="home-section two" id="two">				
					<div className="home-bg">	
					
						<div className="content-wrapper">
						<div className="white-wrap">
						    {this.state.home_banner_content_two}
						</div>
						</div>
						
						<a href="#three" className="scroll_bottom"><img src={scroll_bottom_blue} alt="" /></a>	
					
					</div>				
				</div> */}

				{/*
				<div className="home-section three" id="three">					
					<div className="home-bg">	
					<video autoplay loop poster={homeBg} id="bg-video">
						<source src={homevideo} type="video/mp4" />
					</video>				
					<button type="button" id="play_button">Play</button>
					<a href="#" className="scroll_bottom"><img src={scroll_bottom} alt="facebook" /></a>	
					</div>				
				</div>
				*/}
				
				{/* <div className="home-section four" id="three">				
					<div className="home-bg">	
					
						<div className="content-wrapper">
						   <div className="grid-box">
						        <img src={img1} />
								<div className="grid-box-inner">
								   <div className="title">
								   <h2>NUFF’ TALK<br/>LET’S<br/>EAT</h2>
								   </div>
								 
								   	<Link to={"/menu"}  className="btn" title="Order Here">Order Here</Link>
								</div>		
						   </div>
						</div>
					<a href="#five" className="scroll_bottom"><img src={scroll_bottom} alt="" /></a>	
					
					</div>				
				</div> */}
				
				
				<div className="contact-section home-section five" id="five">
				
				<Form contactinfo={this.state.contactinfo} fields={this.state.fields} onChange={this.fieldChange} onValid={this.handleFormSubmit} contMapFun={this.contMapHtml} socialsettingData={socialsettingData} onInvalid={() => console.log('Form invalid!')} />
				

				</div>
				
				<div className="home-footer" id="home-footer">				
					<div className="home-footer-inner">
						<div className="footer-grid">
						
						<Link to={"/"} title="Home"><img src={fLogo} alt="logo" /></Link>

						<ul>
							<li><Link to={"/"} title="Home"><span>Home</span></Link></li>
								<li><Link to={"/menu"} title="Menu"><span>Menu</span></Link></li>
								{this.state.menudata && this.listMainNavigation()}	
								<li><Link to={"/contact-us"} title="Contact Us"><span>Contact Us</span></Link></li>	
						</ul>
						</div>
						<div className="footer-grid footer-address">
						<h4>Follow Us:
							{instagram}
							{facebook}				
							{email_info}	
						</h4>
						
						{this.state.footer_contact_info}
						</div>
						<div className="footer-grid copyright">
						<p>Copyright {yearSp} Chix Hot Chicken All rights reserved. Design By Jankosoft</p>
						</div>
					</div>
						<a href="#home-banner" className="scroll_top"><img src={scroll_top} alt="top" /></a>
				</div>


                    {/*-------Footer------*/}
					{/*--
					<div className="footer footer-fixed">
					   <Footer />
					</div>
					--*/}
			    </div>
			   )
	  }	
}

const phonenumberPattern = /^[0-9]{6,14}$/;

const isMobile = (mobile) =>
	mobile.match(phonenumberPattern) ? null : 'please enter valid Phone number.'

const isEmpty = (value) =>
	value === '' ? 'This field is required.' : null

const isEmail = (email) =>
	validator.isEmail(email) ? null : 'This is not a valid email.'

function validationConfig(props) {
	const { name, mobile, email, message } = props.fields

	return {
		fields: ['name', 'mobile', 'email', 'message'],

		validations: {
			name: [[isEmpty, name]],
			mobile: [[isEmpty, mobile], [isMobile, mobile]],
			email: [[isEmpty, email], [isEmail, email]],
			message: [[isEmpty, message]]
		}
	}
}



class Form extends React.Component {

	state = {
		pageTitle: '',
		pageDesc: ''
	}



	render() {
		
		
		const { fields, onChange, onValid, onInvalid, $field, $validation } = this.props
		let errMsgFirstName, errMsgEmail, errMsgMobile, errMsgType, errMsgMessage;
		
		let contactcontent ='';
		
		if(this.props.contactContent != undefined)
		{
			contactcontent = Parser(this.props.contactContent.result_set[0].staticblocks_description);
		}
		
		
		let facebook,instagram,email_info ='';

		if(this.props.socialsettingData){

			let socialsettingData = this.props.socialsettingData;
	
			if(socialsettingData.socailnetwork_facebook_url){
				facebook = <a href={socialsettingData.socailnetwork_facebook_url}  target="_blank" className="fb"><img src={FB_blue} alt="facebook" /></a>
			}

			if(socialsettingData.socailnetwork_instagram_url){
				instagram = <a href={socialsettingData.socailnetwork_instagram_url} target="_blank" className="insta">
				<img src={Instgram_blue} alt="instagram" /></a>
			}
		
			if(socialsettingData.socailnetwork_gplus_url){
				email_info = <a href={"mailto:"+socialsettingData.socailnetwork_gplus_url} target="_top" rel="noopener noreferrer" className="email"><img src={mail_blue} alt="mail" /></a>
			}
			
			
		}
		

		if ($validation.name.error.reason !== undefined) {
			document.getElementsByClassName('name').className = 'error';
			errMsgFirstName = ($validation.name.show && <span className="error">{$validation.name.error.reason}</span>)
		}

		if ($validation.mobile.error.reason !== undefined) {
			errMsgMobile = ($validation.mobile.show && <span className="error">{$validation.mobile.error.reason}</span>)
		}

		if ($validation.email.error.reason !== undefined) {

			errMsgEmail = ($validation.email.show && <span className="error">{$validation.email.error.reason}</span>)
		}

		if ($validation.message.error.reason !== undefined) {
			errMsgMessage = ($validation.message.show && <span className="error">{$validation.message.error.reason}</span>)
		}

		return (
			<>
			
			
			<div className="contact-details">								
						<div className="address-details">	
							{this.props.contactinfo}	
							
							<div className="social-media">	
								{instagram}
								{facebook}
								{email_info}			
							</div>
						</div>					
						<div className="map-details">
							{this.props.contMapFun()}
						</div>
			</div>
			
			
			<div className="contact-form">	

						   <div className="title">
						   <h2>WE WOULD LOVE TO<br/> HEAR FROM YOU</h2>
						   </div>
						   
						<div className="form-group">
						<form className="form_sec clear">
							<div className="row">
							
							<div className="col-md-5">
									<div className='focus-out'>
										<label>Name*</label>
									<input type="input" className="form-control input-focus" value={fields.name} maxLength="80"   {...$field('name', (e) => onChange('name', e.target.value))} />
									{errMsgFirstName}
									</div>
									<div className='focus-out'>
										<label>Email*</label>
									<input type="input" className="form-control input-focus" value={fields.email} maxLength="85"   {...$field('email', (e) => onChange('email', e.target.value))} />
									{errMsgEmail}
									</div>
									<div className='focus-out'>
										<label>Contact Number*</label>
									<input type="text" className="form-control input-focus" value={fields.mobile} maxLength="11"   {...$field('mobile', (e) => onChange('mobile', e.target.value))} />
									{errMsgMobile}
									</div>
								</div>
								
								<div className="col-md-7">
									<div className='focus-out'>
										<label>Message*</label>

									<textarea className="form-control input-focus" id="feedback"   {...$field('message', (e) => onChange('message', e.target.value))}></textarea>
									{errMsgMessage}
									</div>
								</div>
								<div className="col-md-12 btn-group">
									
								<button type="button" className="btn" onClick={(e) => { e.preventDefault(); this.props.$submit(onValid, onInvalid); }}>Submit</button>					
								</div>							
							</div>
							</form>
						</div>
			</div>
			

		              
			</>

		)
	}
}
Form = validated(validationConfig)(Form)




const mapStateTopProps = (state) => {
	
	var blockArr = Array();
	if(Object.keys(state.staticblack).length > 0) {
		if(state.staticblack[0].status === 'ok') {
			blockArr = state.staticblack[0].result_set;
		}
	}
	
	var zonedetailArr = Array();
	if(Object.keys(state.zonedetail).length > 0) {
		   if(state.zonedetail[0].status === 'ok') {
			   zonedetailArr = state.zonedetail[0].result_set;
		   }
	}

	return {
		 globalsettings: state.settings,
		 zonedetails: zonedetailArr,
	  menudata: state.menudata,
	  socialsettingData: state.socialsettings,
	  contactdata: state.contactdata,
		staticblock: blockArr
	}
  }

  const mapDispatchToProps = (dispatch) => {
	return {
	 getGlobalSettings: () => {
		dispatch({ type: GET_GLOBAL_SETTINGS });
	  },
	  getMenuData: (menuslug) => {
		dispatch({ type: GET_MENUDATA, menuslug });
	  },
	   getZoneDetail: (outletId, zoneId) => {
		dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId });
	  },
	   getContactData: (postObject) => {
      dispatch({ type: GET_CONTACTDATA, postObject});
	},
	getStaticBlock: () => {
		dispatch({ type: GET_STATIC_BLOCK });
	  },
	  getSocialsetting: () => {
		dispatch({ type: GET_SOCIALSETTINGS });
	  }
	}
  }

export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Home));

