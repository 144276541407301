/* eslint-disable */
import React, { Component } from 'react';

import { Link} from 'react-router-dom';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { stripslashes, hideLoader, showAlert} from "../Helpers/SettingHelper";

import { appId, apiUrl, apiUrlV2, timThumpUrl } from "../Helpers/Config";

var Parser = require('html-react-parser');

import update from 'immutability-helper'

import { validated } from 'react-custom-validation'
import validator from 'validator'

import Header from "../Layout/Header"
import Footer from "../Layout/Footer"

import { connect } from 'react-redux';

import { GET_CONTACTDATA, GET_CONTACTCONTENT,GET_STATIC_BLOCK} from '../../actions';

var Parser = require('html-react-parser');

import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
 
import MapContainer from './Googlemap'

class Contact extends Component {

    constructor(props) {
        super(props);

        this.state = {
			fields: {
				name: '',
				mobile: '',
				subject: '',
				email: '',
				type: '',
				message: '',
			}, pageTitle: '', pageDesc: '', status: '', windowHeight: 0,
			contactinfo:''
			};

    }

    fieldChange = (field, value) => {
		this.setState(update(this.state, { fields: { [field]: { $set: value } } }))
	}
	
	handleFormSubmit = () => {
		const formPayload = this.state.fields;

		var qs = require('qs');
		var postObject = {
			"app_id": appId,
			"customer_first_name": formPayload.name,
			"customer_emailaddress": formPayload.email,
			"customer_phonenumber": formPayload.mobile,
			"subject": formPayload.type,
			"customer_message": formPayload.message
		};


        this.props.getContactData(qs.stringify(postObject));

	}

    componentDidMount() {
		
		this.props.getStaticBlock();
		$('html, body').animate({ scrollTop: 0 }, 800);
	}
	
	handleShowAlertFun(header,msg) {
		var magnfPopup = $.magnificPopup.instance;
		showAlert(header, msg, magnfPopup);
		$.magnificPopup.open({
		  items: {
			src: '.alert_popup'
		  },
		  type: 'inline'
		});
    }

    componentWillReceiveProps(nextProps) {

		if(nextProps.contactdata !== this.props.contactdata)
		{	
			if(nextProps.contactdata[0].status === 'ok')
			{
				this.handleShowAlertFun('success',nextProps.contactdata[0].message);

				setTimeout(function () {

					window.location.reload();

				}, 3000);
			}
			else {
				this.handleShowAlertFun('success',nextProps.contactdata[0].message);

				$.magnificPopup.open({
					items: {
						src: '.alert_popup'
					},
					type: 'inline'
				});

			}
		}

		if(nextProps.staticblock !== this.props.staticblock) {
			var contactinfo = '';

			if(Object.keys(nextProps.staticblock).length > 0) {
				nextProps.staticblock.map((data, index) => {
				if(data.staticblocks_slug === 'contact-us-contact-info') {
					contactinfo = data.staticblocks_description;
				}
			  });
			}
	
			contactinfo = (contactinfo !== '') ? Parser(contactinfo) : contactinfo;
			
			this.setState({contactinfo: contactinfo});
		}

   		 $('html, body').animate({ scrollTop: 0 }, 800); 

	}
	
	sateValChangefn(outlvalue) {
		this.setState({outlet_id: outlvalue});
	}
	
	callPhoneOptn(phoneTxt) {
		var resultTxt = '';
		if(phoneTxt !== '') {
			if(phoneTxt.indexOf("+65") !== -1) {
				resultTxt = "tel:"+phoneTxt;
			} else if((phoneTxt.indexOf("65") !== -1) && (phoneTxt.length >= 10)) {
				resultTxt = "tel:+"+phoneTxt;
			} else {
				resultTxt = "tel:+65"+phoneTxt;
			}
		} else {
			resultTxt = 'javascript:void(0);';
		}
		
		return resultTxt;
	}
	
	contMapHtml = () =>{
		
		return (<div>
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7866803955712!2d103.85657775092463!3d1.3029702620811001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19f86aeaf327%3A0x67a2bbebf321db87!2sChix%20Hot%20Chicken%20%7C%20Nashville%20Hot%20Fried%20Chicken%20Singapore!5e0!3m2!1sen!2sin!4v1588072588215!5m2!1sen!2sin" width="600" height="450" frameBorder="0" allowFullScreen></iframe>
				</div>);
		
	}

render() {

  return (<div className="home-wrapper inner-page-wrapper">

			<Header />
		
			<div className="contact_wrap">
			
				{/*}	<div className="inner_banner">
				<img src={ContactUsImg} />
				</div>{*/}
				
				<div className="container">
					<div className="contactus_wrapper">							
						<Form contactinfo={this.state.contactinfo} fields={this.state.fields} onChange={this.fieldChange} onValid={this.handleFormSubmit} outletListData={this.getOutletData} contMapFun={this.contMapHtml} onInvalid={() => console.log('Form invalid!')} />							
					</div>
				</div>

			</div>
			
			
			<div className="footer footer-bg">
				<Footer />
			</div>      
		
		</div>);	

  }
}

const phonenumberPattern = /^[0-9]{6,14}$/;

const isMobile = (mobile) =>
	mobile.match(phonenumberPattern) ? null : 'please enter valid Phone number.'

const isEmpty = (value) =>
	value === '' ? 'This field is required.' : null

const isEmail = (email) =>
	validator.isEmail(email) ? null : 'This is not a valid email.'

function validationConfig(props) {
	const { name, mobile, email, message } = props.fields

	return {
		fields: ['name', 'mobile', 'email', 'message'],

		validations: {
			name: [[isEmpty, name]],
			mobile: [[isEmpty, mobile], [isMobile, mobile]],
			email: [[isEmpty, email], [isEmail, email]],
			message: [[isEmpty, message]]
		}
	}
}



class Form extends React.Component {

	state = {
		pageTitle: '',
		pageDesc: ''
	}



	render() {
		
		
		const { fields, onChange, onValid, onInvalid, $field, $validation } = this.props
		let errMsgFirstName, errMsgEmail, errMsgMobile, errMsgType, errMsgMessage;
		
		let contactcontent ='';
		
		if(this.props.contactContent != undefined)
		{
			contactcontent = Parser(this.props.contactContent.result_set[0].staticblocks_description);
		}

		if ($validation.name.error.reason !== undefined) {
			document.getElementsByClassName('name').className = 'error';
			errMsgFirstName = ($validation.name.show && <span className="error">{$validation.name.error.reason}</span>)
		}

		if ($validation.mobile.error.reason !== undefined) {
			errMsgMobile = ($validation.mobile.show && <span className="error">{$validation.mobile.error.reason}</span>)
		}

		if ($validation.email.error.reason !== undefined) {

			errMsgEmail = ($validation.email.show && <span className="error">{$validation.email.error.reason}</span>)
		}

		if ($validation.message.error.reason !== undefined) {
			errMsgMessage = ($validation.message.show && <span className="error">{$validation.message.error.reason}</span>)
		}

		return (
			<>
				<div className="locate-map">					
						<div className="locate-mapleft">
							<div className="locate-list">
								{/*this.props.outletListData()*/}

								<div className="locate-lirow">
									{this.props.contactinfo}
								</div>

							</div>
						</div>
						<div className="locate-mapright">
							<div id="locateGmap">
								
							{this.props.contMapFun()}
							</div>
						</div>
				</div>
				<div className="contact_form">
					<h3>DROP US A LINE</h3>
					<form className="form_sec clear">
						<div className="contact_col">
							<div className="form-group">
								<div className="focus-out">
									<label>Name*</label>
									<input type="input" className="form-control input-focus" value={fields.name} maxLength="80"   {...$field('name', (e) => onChange('name', e.target.value))} />
									{errMsgFirstName}
								</div>								
							</div>
							<div className="form-group">
								<div className="focus-out">
									<label>Contact Number*</label>
									<input type="text" className="form-control input-focus" value={fields.mobile} maxLength="11"   {...$field('mobile', (e) => onChange('mobile', e.target.value))} />
									{errMsgMobile}
								</div>
							</div>
							<div className="form-group">
								<div className="focus-out">
									<label>Email*</label>
									<input type="input" className="form-control input-focus" value={fields.email} maxLength="85"   {...$field('email', (e) => onChange('email', e.target.value))} />
									{errMsgEmail}
								</div>
							</div>
						</div>
						{/*<div className="form-group">
								<div className="re_select">
									<select className="form-control select-gender" {...$field('type', (e) => onChange('type', e.target.value)) }>
										<option value=""> Please Select* </option>
										<option value="General Enquiry" id="general-enquiry"> General Enquiry </option>
										<option value="Order Enquiry" id="order-enquiry"> Order Enquiry </option>
										<option value="Others" id="others"> Others </option>
									</select>

									{errMsgType}

								</div>
							</div>*/}
						<div className="contact_col">
							<div className="form-group">
								<div className="focus-out">
									<label>Message*</label>

									<textarea className="form-control input-focus" id="feedback"   {...$field('message', (e) => onChange('message', e.target.value))}></textarea>
									{errMsgMessage}

								</div>
							</div>
							<div className="btn_sec">
								<button type="button" className="btn btn_black btn-lg btn-block" onClick={(e) => { e.preventDefault(); this.props.$submit(onValid, onInvalid); }}>Submit<div className="ripple-container"></div></button><br />

							</div>
						</div>
					</form>
				</div>

		              
			</>

		)
	}
}
Form = validated(validationConfig)(Form)

const mapStateToProps = (state) => {

	var blockArr = Array();
	if(Object.keys(state.staticblack).length > 0) {
		if(state.staticblack[0].status === 'ok') {
			blockArr = state.staticblack[0].result_set;
		}
	}

  return {
	contactdata: state.contactdata,
	staticblock: blockArr
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	
    getContactData: (postObject) => {
      dispatch({ type: GET_CONTACTDATA, postObject});
	},
	getStaticBlock: () => {
		dispatch({ type: GET_STATIC_BLOCK });
	  },
   
  }
}


Contact.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }) 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contact));



