/* eslint-disable */
import React, { Component } from 'react';
import { validated } from 'react-custom-validation'
import validator from 'validator';
import axios from 'axios';

import cookie from 'react-cookies';

import DatePicker from 'react-datepicker';
import { setMinutes, setHours, format } from 'date-fns'
import 'react-datepicker/dist/react-datepicker.css';

import { appId, apiUrl } from "../Helpers/Config";
import { getReferenceID, showAlert} from "../Helpers/SettingHelper";

var header = {
    headers: { 'X-API-KEY': 'D04Ea1c5-b19c-4B58-b2e9-88F5C0456432' }
  }
  

const isEmpty = (value) =>
value === '' ? 'This field is required.' : null

const isEmail = (email) =>
validator.isEmail(email) ? null : 'This is not a valid email.'

const phonenumberPattern = /^[0-9]{6,8}$/;
const isMobile = (mobile) =>
    mobile.match(phonenumberPattern) ? null : 'please enter valid Phone number.'

const isChecked = (termsa) => termsa ? null : "Please accept terms and conditions"


function validationConfigCheckout(props) {
    const { check_firstname, check_lastname, check_email, birthday, check_phone,termsa } = props.fields
    return {
        fields: ['firstname', 'email', 'birthday', 'mobile'],

        validations: {
            firstname: [
                [isEmpty, check_firstname]
            ],
            email: [
                [isEmail, check_email]
            ],
			birthday: [
                [isEmpty, birthday]
            ],
            mobile: [
                [isEmpty, check_phone],
                [isMobile, check_phone]
            ],
            termsa: [
                [isChecked, termsa, 1],
            ],
        }
    }
}



class Guestcheckout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: 'Loading',
            check_phone : ''
        };
    }

    componentDidMount() {
        var check_phone = this.props.fields.check_phone;
        this.setState({'check_phone': check_phone});
        //,client_otp_verification_enable:this.props.settings.client_otp_verification_enable,client_otp_verification_via:this.props.settings.client_otp_verification_via
    }

    componentWillReceiveProps(nextProps) {
        this.setState({'check_phone': nextProps.fields.check_phone});
    }

    isGuestPhonenumerExist(){
        var mobile = $("#check_phone").val();  
        this.setState({'check_phone' : mobile });
        this.props.onChange('check_phone',mobile);
        if(mobile.length > 7){
            axios.get(apiUrl + "guestaccount/guest_customer_phone?app_id="+appId+"&status=A&phone_no="+mobile, header).then(response => {
                if (response.data.status === "ok") {

                    this.props.onChange('check_phone',mobile);

					//let company_otp_verification = '1';
					//let company_otp_via = '2';
					
					let company_otp_verification = '';
					let company_otp_via = '';
					
					 document.getElementById("spn-otp-success").innerHTML='';
					 document.getElementById("spn-otp-error").innerHTML='';
					if(company_otp_verification === '1'){
					//if(company_otp_verification === '1' && mobile !== '12343456'){
					
						console.log(company_otp_verification,company_otp_via,'company_otp_via');

						document.getElementById("otp_verification_option").style.display = "block";

						document.getElementById("register_form_option").style.display = "none";
						document.getElementById("register_form_header").style.display = "none";
						
						let qs = require('qs');
						let postObject = {
							"app_id": appId,
							"customer_first_name" :response.data.result_set.customer_first_name,
							"customer_last_name":response.data.result_set.customer_last_name,
							"customer_email" :response.data.result_set.customer_email,
							"customer_phone" : mobile,
							"customer_otp_type": company_otp_via
						};

						cookie.save('customer_email',response.data.result_set.customer_email);
						cookie.save('customer_phone',mobile);

						axios.post(apiUrl+"customer/send_customer_otp", qs.stringify(postObject), header)
						.then(response => {
							if(response.data.status==="ok"){

							    document.getElementById("spn-otp-success").innerHTML=response.data.message;

							} else {

								document.getElementById("spn-otp-error").innerHTML=response.data.message;

							}
							return false;
						}).catch((error) => {
						console.log(error);
						document.getElementById("spn-otp-error").innerHTML=error;
						return false;
						});

					} else {

						/*this.props.onChange('check_firstname', response.data.result_set.customer_first_name);
						this.props.onChange('check_email', response.data.result_set.customer_email);

						var birthdate = response.data.result_set.customer_birthdate;
						if(birthdate !=='' && birthdate !== '0000-00-00' && birthdate !== '00/00/0000'){
							birthdate = new Date(birthdate);
							this.props.onChange('birthday', birthdate);
						}*/
					
					}

                } else {
                    this.props.onChange('check_phone',mobile);
                }
            });
        }else{
            this.setState({'check_phone': mobile});
        }
    }
	
	guestChgDate(datevalue) {
        var dateval = new Date(datevalue);
		dateval = format(dateval, "dd/MM/yyyy");
        this.props.onChange("birthday",datevalue)
    }
    
      backto_register() {
		document.getElementById("register_form_option").style.display = "block";
		document.getElementById("register_form_header").style.display = "block";
		document.getElementById("otp_verification_option").style.display = "none";
		document.getElementById("resend_otp_button").style.display = "block";
	  }
	  
	   submit_otp_verification() {
		   
		document.getElementById("spn-otp-success").innerHTML='';
		document.getElementById("spn-otp-error").innerHTML='';

		var customer_otp_val = this.state.customer_otp_val;
		
		if(customer_otp_val != "" && customer_otp_val != undefined){

			var qs = require('qs');
			var postObject = {"app_id": appId,"customer_otp_val" : customer_otp_val,"customer_email_id":cookie.load('customer_email'),"customer_phone_no":cookie.load('customer_phone'),"verify_type":"register"}; 

			axios.post(apiUrl+"customer/customer_otp_verification", qs.stringify(postObject), header).then(response => {  
				if(response.data.status==="ok") {

					document.getElementById("spn-otp-success").innerHTML=response.data.message;

					cookie.remove('customer_email');
					cookie.remove('customer_phone');

					/* set User cookie values - Start */
					cookie.save("Token", response.data.result_set.token);

					localStorage.setItem('BeaerToken', response.data.result_set.token);

					cookie.save("UserId", response.data.result_set.customer_id);
					cookie.save("UserFname", (response.data.result_set.customer_first_name !== "" ? response.data.result_set.customer_first_name : ''));
					cookie.save("UserLname", (response.data.result_set.customer_last_name != "" ? response.data.result_set.customer_last_name : ''));
					cookie.save("UserMobile", response.data.result_set.customer_phone);
					cookie.save("UserEmail", response.data.result_set.customer_email);
					cookie.save("UserDefaultAddress", response.data.result_set.customer_address_name);
					cookie.save("UserDefaultUnitOne", response.data.result_set.customer_address_line1);
					cookie.save("UserDefaultUnitTwo", response.data.result_set.customer_address_line2);
					cookie.save("UserDefaultPostalCode", response.data.result_set.customer_postal_code);
					cookie.save("userAccountType",1);
					const{history} = this.props;

					var qs = require('qs');
					var postObject = {
						"app_id": appId,
						"reference_id": getReferenceID(),
						"customer_id": response.data.result_set.customer_id,
						"availability_id": cookie.load('defaultAvilablityId')
					};

					//if(cookie.load('Token')!='' && cookie.load('Token')!=undefined) {
						header.headers.Auth = response.data.result_set.token
					//}

					axios.post(apiUrl + "cart/update_customer_info", qs.stringify(postObject), header).then(res => {
							showAlert('Success', 'Logged in Successfully!');
							$.magnificPopup.open({
							items: {
								src: '.alert_popup'
							},
							type: 'inline'
							});
						if (res.data.status === "ok") {
							if(cookie.load('loginpopupTrigger')==='fromcheckout') {
								cookie.remove('loginpopupTrigger');
								history.push("/checkout");
							} else {
								history.push("/myaccount");
							}
						} else {
							
							history.push("/myaccount");
							
						}
					});

				} else {

					document.getElementById("spn-otp-error").innerHTML=response.data.message;

				}
			});

		} else {

			document.getElementById("spn-otp-error").innerHTML='Enter your valid OTP';

		}

	  }
	  
	   handleChangeTxt = (item, event) => {  
		this.setState({[item]:event.target.value });

		document.getElementById("spn-otp-error").innerHTML=''; 
	  }

	handleResendotp() {

		document.getElementById("spn-otp-success").innerHTML='';
		document.getElementById("spn-otp-error").innerHTML='';

		var qs = require('qs');
		var postObject = {"app_id": appId,"customer_email" : cookie.load('customer_email'),"customer_mobile":cookie.load('customer_phone'),"customer_first_name":cookie.load('customer_first_name'),"customer_last_name":cookie.load('customer_last_name')}; 

		axios.post(apiUrl+"customer/resend_otp", qs.stringify(postObject), header).then(response => {  
			if(response.data.status==="ok"){

				document.getElementById("resend_otp_button").style.display = "none"; 
				document.getElementById("spn-otp-success").innerHTML=response.data.message;
			} else {
				document.getElementById("spn-otp-error").innerHTML=response.data.message;
			} 
		});

	  }

    render() {

        const { fields, onChange, onValid, onInvalid, $field, $validation } = this.props
        
        let errMsgFirstName, errMsgLastName, errMsgEmail, errMsgBirthday, errMsgMobile, errMsgTerms;

        if ($validation.firstname.error.reason !== undefined) {
            errMsgFirstName = ($validation.firstname.show && <span className="error">{$validation.firstname.error.reason}</span>)
        }
        if ($validation.email.error.reason !== undefined) {
            errMsgEmail = ($validation.email.show && <span className="error">{$validation.email.error.reason}</span>)
        }
        if ($validation.mobile.error.reason !== undefined) {
            errMsgMobile = ($validation.mobile.show && <span className="error">{$validation.mobile.error.reason}</span>)
        }
		if ($validation.birthday.error.reason !== undefined) {
            errMsgBirthday = ($validation.birthday.show && <span className="error">{$validation.birthday.error.reason}</span>)
        }

        if ($validation.termsa.error.reason !== undefined) {
            errMsgTerms = ($validation.termsa.show && <span className="error">{$validation.termsa.error.reason}</span>)
        }
 
        return (

            <div className="popup-body">

				<span className="guest-chk-error"></span>

               <div id="register_form_option">
                <div className="form-group">
                    <div className="focus-out">
                        <label>Phone number</label>
                        <input type="tel" maxLength="8" id="check_phone" className="form-control input-focus" pattern="\d*" value={this.state.check_phone} onChange ={()=>this.isGuestPhonenumerExist()} />
                        <div id="spn-mobile-error">{errMsgMobile}</div>
                    </div>
                </div>
                <div className="form-group">
                    <div className={(fields.check_firstname != '' ? 'focus-out focused' : 'focus-out')}>
                        <label>Name</label>
                        <input type="text" className="form-control input-focus" value={fields.check_firstname} {...$field('check_firstname', (e) => onChange('check_firstname', e.target.value)) } />
                        {errMsgFirstName}
                    </div>

                </div>
               
                <div className="form-group">
                    <div className={(fields.check_email != '' ? 'focus-out focused' : 'focus-out')}>
                        <label>Email</label>
                        <input type="text" className="form-control input-focus" value={fields.check_email} {...$field('check_email', (e) => onChange('check_email', e.target.value)) } />
                        <div id="spn-email-error">{errMsgEmail}</div>
                    </div>
                </div>
				
				<div className="form-group">
                    <div className={(fields.birthday != '' ? 'focus-out focused' : 'focus-out')}>
                        <label>Birthday</label>
                       <div className="react_datepicker">
						<DatePicker peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" 
							className="form-control input-focus" dateFormat="dd/MM/yyyy" selected={fields.birthday} maxDate={new Date()} onChange={this.guestChgDate.bind(this)} />

							{errMsgBirthday}
						</div>
						<span className="birthday_info_sp">[ * <b>Used to send vouchers and promotions.</b> ]</span>
                    </div>
                </div>
				
				<div className="form-group">
                        <div className="custom_checkbox custom_checkbox_content"> 
                        <input type="checkbox" id="termsa" checked={fields.termsa}   {...$field('termsa', (e) => onChange("termsa", e.target.value), null, false) } /> <span></span>
                            <div className="sign_reg">
                            <p className="pdpa_trmcont_link">By Continuing you agree to the <a className="sign_reg_uline" href="/page/terms-and-conditions" target="_blank">PDPA Terms and conditions</a>.</p>
                            </div>

                        </div>
                        {errMsgTerms}
                 </div>
				
                <div className="form-group">
                    <div className="login_pop_sub login_in_guest">
                        <button type="button" className="btn btn_black btn_minwid guestcheckout_submit" onClick={(e) => { e.preventDefault(); this.props.$submit(onValid, onInvalid); }}>Submit</button>
                    </div>
                </div>
             </div>

                 <div className="space-20" style={{display: 'none'}} id="otp_verification_option">
					<a className="back_to_register" onClick={this.backto_register.bind(this)}>Back</a>		
						<p className="text-center font-bold font-size-18">OTP Verification</p>
						<form className="customer_otp" id="customer_otp" onSubmit={ e => { this.submit_otp_verification(this); e.preventDefault(); }}>

						<div id="spn-otp-error" className="error"></div>
						<div id="spn-otp-success" className="success"></div>

							<div className="form-group label-floating card-no input-form-group">
								<div className="input-group">
									<label className="control-label">Enter your OTP<span>*</span></label> 
									<input type="text" name="customer_otp_val" className="form-control" value={this.state.customer_otp_val} onChange={this.handleChangeTxt.bind(this, 'customer_otp_val')}/>
									<div  id="spn-otp-error"></div>
								</div>
							</div>

							<div className="text-center otp-btn-group">
								<button type="button" className="btn btn_black btn_minwid " onClick={this.submit_otp_verification.bind(this)}>Submit</button>
								<p className="otp"><a className="resent_otp" id="resend_otp_button" onClick={this.handleResendotp.bind(this)}>Resend OTP</a></p>	
							</div>
							
							
						</form>

				</div>

            </div>
        );
    }
}
Guestcheckout = validated(validationConfigCheckout)(Guestcheckout)

export default Guestcheckout;
