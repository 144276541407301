import { all } from 'redux-saga/effects'

import { watchGetSettings } from './settings';
import { watchGetSocialsetting } from './socialsettings';
import { watchGetZonedetail } from './zonedetail';
import { watchGetSecAddress, watchAddSecAddress } from './secondaryaddress';

import { watchGetCartDetail, watchUpdateCartDetail, watchDeleteCartDetail, watchDestroyCartDetail } from './cartlist';
import { watchGetActivityCount } from './activitycount';

import { watchGetMenuNavigation, watchGetProducts } from './product';
import { watchGetProductDetail } from './productdetail';
import { watchGetAddonPro } from './addonproduct';

import { watchGetOrderDetail } from './orderdetail';
import { watchGetOrderHistory } from './orderhistory';
import { watchGetPrintOrder } from './printorder';
import { watchGetCorderDetail } from './corderdetail';
import { watchGetPorderDetail } from './porderdetail';
import { watchGetPromotionList } from './promotionlist';

import { watchGetPromotionReceipt } from './promotionreceipt';
import { watchGetApplyPromotion } from './applypromotion';
import { watchGetRewardEarned } from './rewardearned';
import { watchGetRewardRedeem } from './rewardredeem';

import { watchGetCustomerDetail } from './customerdetail';
import { watchGetUpdateCustomerProfile } from './updatecustomerprofile';

import { watchGetMenuData } from './menudata';
import { watchGetRequestpage } from './pages';
import { watchGetContactData } from './contactdata';

import { watchGetStaticBlack } from './staticblack';

export default function* () {
  yield all([
      watchGetMenuNavigation(),
      watchGetProducts(),
      watchGetSettings(),
      watchGetZonedetail(),
      watchGetSecAddress(),
      watchAddSecAddress(),
      watchGetCartDetail(),
      watchUpdateCartDetail(),
      watchDeleteCartDetail(),
      watchDestroyCartDetail(),
      watchGetActivityCount(),
      watchGetOrderDetail(),
      watchGetCorderDetail(),
      watchGetPorderDetail(),
      watchGetPrintOrder(),
      watchGetOrderHistory(),
      watchGetPromotionList(),
      watchGetPromotionReceipt(),
      watchGetApplyPromotion(),
      watchGetRewardEarned(),
      watchGetRewardRedeem(),
      watchGetCustomerDetail(),
      watchGetUpdateCustomerProfile(),
      watchGetMenuData(),
      watchGetRequestpage(),
      watchGetContactData(),
      watchGetAddonPro(),
      watchGetProductDetail(),
      watchGetStaticBlack(),
      watchGetSocialsetting()
   ]);
}
