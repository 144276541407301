/* eslint-disable */
if (location.protocol !== "https:") {
  var locationULR = location.href.replace("www.", "");
  location.href = locationULR.replace("http://", "https://www.");
} else if (location.protocol === "https:") {
  var locationULR = location.href;
  if (locationULR.indexOf("www.") < 0) {
    location.href = "https://www." + locationULR.replace("https://", "");
  }
}
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "./components/Home";

import Products from "./components/Products";
import ProductDetail from "./components/Products/ProductDetail";

import Checkout from "./components/Checkout";
import Thankyou from "./components/Checkout/Thankyou";

import Myaccount from "./components/Myaccount/Myaccount";
import Orders from "./components/Myaccount/Orders";
import Mypromotions from "./components/Myaccount/Mypromotions";
import Rewards from "./components/Myaccount/Rewards";
import Logout from "./components/Myaccount/Logout";

import Pages from "./components/Pages/Pages";
import ContactUs from "./components/Pages/ContactUs";

import Page404 from "./Page404";

class App extends Component {
  componentDidMount() {}

  render() {
    return (
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/Home" component={Home} />
          <Route
            path={"/products/:slugType/:slugValue/:proSlug"}
            component={ProductDetail}
          />
          <Route path={"/menu/:slugType/:slugValue"} component={Products} />
          <Route path="/menu" component={Products} />
          <Route exact path="/menu" component={Products} />
          <Route exact path="/checkout" component={Checkout} />
          <Route path="/thankyou/:orderId" component={Thankyou} />

          <Route path="/myaccount" component={Myaccount} />
          <Route path="/mypromotions" component={Mypromotions} />
          <Route path="/rewards" component={Rewards} />
          <Route path="/myorders" component={Orders} />

          <Route path="/page/:page_slug" component={Pages} />
          <Route path="/contact-us" component={ContactUs} />

          <Route path="/logout" component={Logout} />

          <Route component={Page404} />
        </Switch>
      </Router>
    );
  }
}
export default App;
