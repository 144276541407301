/* eslint-disable */
import React, { Component } from 'react';

import { Link} from 'react-router-dom';

import { stripslashes, hideLoader } from "../Helpers/SettingHelper";

import Header from "../Layout/Header"
import Footer from "../Layout/Footer"

var Parser = require('html-react-parser');


import { connect } from 'react-redux';

import { GET_REQUESTPAGEDATA} from '../../actions';


import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';



class Pages extends Component {

    constructor(props) {
        super(props);
		this.state = {pagedata:[],pagedetail:'',pagetitle:''};
		const {page_slug}= this.props.match.params;
		this.props.getRequestpage(page_slug);
    }

    componentDidMount() {
		 $('.dvLoadrCls').show();
		 setTimeout(function(){ $('.test-popup-link').magnificPopup({type:'image'}); }, 2000);
    }
    
    componentWillReceiveProps(nextProps) {
		 if (nextProps.match.params.page_slug !== this.props.match.params.page_slug) {
			  if ($(".trigger_menu").length > 0) {
                $('.trigger_menu').toggleClass('active');
				if ($('.hmenu_list').hasClass('open')) {
                $('.mega_menu').slideUp();
				}
				$('.hmenu_list').toggleClass('open');
			  }
			  this.props.getRequestpage(nextProps.match.params.page_slug);
		 }

		 if(nextProps.pagedata !== this.state.pagedata) {

			 $('.dvLoadrCls').fadeOut(500);
			 var pagedataTxt = (Object.keys(nextProps.pagedata).length > 0) ? nextProps.pagedata[0].cmspage_description : '';
			 var cmspage_title = (Object.keys(nextProps.pagedata).length > 0) ? nextProps.pagedata[0].cmspage_title : '';

			 var pageDetails = (pagedataTxt !== '') ? Parser(pagedataTxt) : '';
			 this.setState({pagedata: nextProps.pagedata, pagedetail: pageDetails,pagetitle: cmspage_title});

			 if(pageDetails =='') {

				this.props.history.push('/');

			 }

		}
	}

    render() {

		return (<div className="home-wrapper inner-page-wrapper">

				<Header />

					<div className="cms-page">
						<div className="wraper cms_wraper">
							<h3 className="title">{stripslashes(this.state.pagetitle)}</h3>
							<div className="container">
								<div className="cms-content-wrapper">
									{this.state.pagedetail}
								</div>
							</div>
						</div>
					</div>
			
				 <div className="footer footer-bg">
				 <Footer />
				 </div>      

					<div id="dvLoading" className="dvLoadrCls"></div>
		       </div>);
    }
}



const mapStateToProps = (state) => {
	
	var pagedataRst = Array();
	if(Object.keys(state.pagedata).length > 0) {
		  if(state.pagedata[0].status === 'ok') {
			 pagedataRst = state.pagedata[0].result_set;
		  }
	}
	
	return {
		pagedata: pagedataRst
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getRequestpage: (slug) => {
		dispatch({ type: GET_REQUESTPAGEDATA, slug});
		},
	}
}

Pages.propTypes = {
	history: PropTypes.shape({
	push: PropTypes.func.isRequired
	}) 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));

 
