/* eslint-disable */
import React, { Component } from 'react';

import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
let px= '360px';
const mapStyles = {
	width: '54%',
	height: '30%',
  };
  
 {/*https://dev.to/jessicabetts/how-to-use-google-maps-api-and-react-js-26c2*/ }

  export class MapContainer extends Component {
	constructor(props) {
	  super(props);
  
	  this.state = {
		stores: [{latitude: 1.3029, longitude: 103.8588},
				/*{latitude: 47.359423, longitude: -122.021071},
				{latitude: 47.2052192687988, longitude: -121.988426208496},
				{latitude: 47.6307081, longitude: -122.1434325},
				{latitude: 47.3084488, longitude: -122.2140121},
		{latitude: 47.5524695, longitude: -122.0425407}*/
	]
	  }
	}
  
	displayMarkers = () => {
	  return this.state.stores.map((store, index) => {
		return <Marker key={index} id={index} position={{
		 lat: store.latitude,
		 lng: store.longitude
	   }}
	   onClick={() => console.log("You clicked me!")} />
	  })
	}
  
	render() {
	  return (
		  <Map
			google={this.props.google}
			zoom={8}
			style={mapStyles}
			initialCenter={{lat: 1.3029, lng: 103.8588}}
		  >
			{this.displayMarkers()}
		  </Map>
	  );
	}
  }
  
   
  export default GoogleApiWrapper({
	apiKey: 'AIzaSyAFc3CyTwCIxobypZsaTHcSZu4T72MX-xw'
  })(MapContainer)

